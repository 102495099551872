<template>
  <v-simple-table fixed-header>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left caption">IMAGE</th>
          <th class="text-left caption">COURSE</th>
          <th class="text-left caption">TEACHER</th>
          <th class="text-left caption">PROGRESS</th>
          <th class="text-left caption"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id">
          <td width="5%">
            <v-avatar size="40" v-if="item.image" tile>
              <v-img
                :src="`${item.aws}/courses/${item.uuid}/image/${item.image.image}`"
              />
            </v-avatar>
            <!-- <v-avatar v-else color="success" size="45" tile/> -->
          </td>
          <td>
            <v-list-item-title>
              <div class="body-2">
                {{ item.title.slice(0, 30)
                }}{{ item.title.length > 30 ? "..." : "" }}
              </div>
            </v-list-item-title>
          </td>
          <td class="body-2">
            {{ `${item.teacher.firstname} ${item.teacher.lastname}` }}
          </td>
          <td>
            <v-progress-linear
              :color="item.progress < 50 ? '#999' : 'success'"
              rounded
              height="20"
              :value="item.progress"
              stream
            >
              {{ Math.ceil(item.progress) }}%
            </v-progress-linear>
          </td>
          <td class="" width="10%">
            <v-btn
              @click="learn(item)"
              :color="$helpers.progressColor(item.action)"
              small
            >
              {{
                item.action == "start"
                  ? "Start Learning"
                  : item.action == "continue"
                  ? "Continue"
                  : "Completed"
              }}
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: ["data", "user"],
  methods: {
    learn(item) {
      console.log(item);
      if(item.lessons_count == 0) {
        alert('No Lesson found!')
        return
      }
      if (item.start.status == 1) {
        if(item.start.type == 'Assessment') {
          this.$router.push({
            name: 'Student Assessment',
            params: { uuid: item.start.assessment.uuid, type: "lesson" },
          });
          return;
        }
        this.$router.push({
          name: 'Student Material',
          params: { uuid: item.start.uuid },
        });
        return;
      }
      
      alert("Next topic is not yet open");
    },
  },
};
</script>